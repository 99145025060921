import React from "react"

import ContentPage from "../../components/contentPage"
import { Link } from "gatsby";
import styles from "./index.module.css";

const ReferralTerms = () => (
  <>
    <ContentPage title={'Referral Program Terms & Conditions'} isSmallFonts={true}>
		<p>The below Terms and Conditions outline the terms that govern MProfit’s Referral Program for all MProfit customers.</p>
        <h4>1. DEFINITIONS</h4>

			<p>
				1.1 "Eligible Products" means paid subscriptions of MProfit Cloud products for Investors and Wealth Professionals as listed on MProfit’s website (www.mprofit.in/pricing).
			</p>

			<p>
				1.2 “MProfit Landing Page” means a webpage on MProfit’s website (www.mprofit.in) via which an individual or entity can sign up for an MProfit account.
			</p>

			<p>
				1.3 “Referral Link” means the unique URL (on the domain www.mprofit.in), provided by MProfit to an MProfit customer, that directs to an MProfit Landing Page. This Referral Link can be shared by the MProfit customer with an individual or entity to sign up first time for an MProfit account.
			</p>

			<p>
				1.4 “Trial User” means a user who signs up first time for an MProfit account on an MProfit Landing Page by clicking the Referral Link. A Trial User can avail a free trial of an Eligible Product as per MProfit’s policy with regards to free trials (that MProfit has the right to amend at any given time). A user who has permanently deleted their MProfit account ceases to be a Trial User.
			</p>

			<p>
				1.5 “Sign-up Date” means the date on which a Trial User signs up first time for an MProfit account on an MProfit Landing Page by clicking the Referral Link.
			</p>

			<p>
				1.6 “Customer Email ID” means the unique email address that a Trial User provides to MProfit while signing up for an MProfit account on an MProfit Landing Page.
			</p>

			<p>
				1.7 “Referral Period” means a period of 90 days from the Sign-up Date of a Trial User during which the Trial User must purchase an Eligible Product for the Referring Customer to be eligible to earn Referral Points.
			</p>

			<p>
				1.8 "Referred Customer" means a Trial User who purchases an Eligible Product during the Referral Period.
			</p>

			<p>
				1.9 “Referring Customer” means an existing MProfit customer, actively subscribed to an Eligible Product, who shares a Referral Link with a Trial User to sign up first time for an MProfit account on an MProfit Landing Page.
			</p>

			<p>
				1.10 "Referral Points" means the reward earned by a Referring Customer for referring a Referred Customer to MProfit.
			</p>

			<p>
				1.11 “Introductory Discount” means the discount offered by MProfit to a Referred Customer for their first purchase of a new MProfit subscription.
			</p>

		<h4>2. COMMERCIAL TERMS</h4>

			<p>
				2.1 Referral Points: The Referring Customer will earn a reward of 1,500 Referral Points from MProfit upon the successful purchase of an Eligible Product by a Referred Customer during the Referral Period.
			</p>

			<p>
				2.2 Eligible Products: Referral Points can be earned by the Referring Partner on the sale of Eligible Products only.
			</p>

			<p>
				2.3 Introductory Discount: Referred Customers will earn a 10% introductory discount on the first purchase of an Eligible Product.
			</p>

			<p>
				2.4 Rewards Conversion: Referring Customers will be eligible to convert their Referral Points to direct discounts on future payments towards the renewal or upgrade of their MProfit subscriptions. The conversion of Referral Points to discounts is as follows: 1 Referral Point = 1 Indian Rupee earned in discounts on future payments towards Eligible Products. For example: if the Referring Customer has successfully referred two new customers and earned 3000 Referral Points, they will be eligible to receive a maximum cash discount of Indian Rupees 3,000 on future renewal or upgrade payments towards Eligible Products. Referral Points do not have a defined expiry date. This means that surplus Referral Points can be carried forward by the Referring Customer towards future payments for Eligible Products. Referral Points cannot be redeemed for cash or transferred to another customer’s account.
			</p>

			<p>
				2.5 Eligibility: For the Referring Customer to be eligible to receive a Referral Commission when a Referred Customer purchases an Eligible Product for the first time, 1) the Referred Customer must have signed up first time for an MProfit account on an MProfit Landing Page by clicking the Referral Link, 2) the Referred Customer must have purchased an Eligible Product using the same email address as the Customer Email ID and 3) the Referred Customer must have purchased an Eligible Product during the Referral Period and not after.
			</p>

			<p>
				2.6 Refunds: In the event that a Referred Customer seeks a refund on the purchase of an Eligible Product, MProfit is entitled to cancel the Referral Points previously credited to the Referring Customer for such referral.
			</p>

		<h4>3. OBLIGATIONS OF THE REFERRING CUSTOMER</h4>

			<p>
				3.1 The Referring Customer shall promote MProfit products in a professional and ethical manner.
			</p>

			<p>
				3.2 The Referring Customer shall not engage in any misleading or deceptive marketing practices, including but not limited to false claims, misrepresentations, or the use of trademarks or logos without prior written consent from MProfit.
			</p>

			<p>
				3.3 The Referring Customer shall not run any kind of paid search or display advertisements on online search engines or advertising platforms (including those owned by Google) to promote the Referral Link.
			</p>

			<p>
				3.4 The Referring Customer shall not engage in any activity that may harm the reputation or goodwill of MProfit.
			</p>

			<p>
				3.5 The Referring Customer shall comply with all applicable laws and regulations in connection with the promotion of MProfit products.
			</p>

		<h4>4. PROGRAM</h4>

			<p>
				4.1 By participating in this Referral Program, the Referring Customer agrees to be bound by the terms and conditions set forth herein, as well as any other rules or guidelines issued by MProfit from time to time.
			</p>

			<p>
				4.2 MProfit reserves the right to terminate the Referral Program or modify or update the Terms and Conditions at any time, in any manner without any prior notification. Any change or revision will be posted on our website [www.mprofit.in].
			</p>

			<p>
				4.3 The Referring Customer agrees that nothing in these Terms and Conditions will create any joint venture, agency, franchise, sales representative, or employment relationship between MProfit and the Referring Customer. The Referring Customer will not have any authority to make or accept any offers or representations on MProfit’s behalf. The Referring Customer will not make any statement, whether on their website or otherwise, that reasonably would contradict anything in these Terms and Conditions.
			</p>

			<p>
				4.4 The Referring Customer may not assign its rights or obligations under the Referral Program to any third party, without expressly receiving the written consent of MProfit.
			</p>

			<p>
				4.5 If any provision of these Terms and Conditions is held to be invalid or unenforceable, that provision shall be eliminated or limited to the minimum extent necessary such that the intent of the parties is effectuated, and the remainder of these Terms and Conditions shall have full force and effect.
			</p>



    </ContentPage>
  </>
)

export default ReferralTerms
