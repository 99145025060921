import React from "react"
import Layout from "../components/flayout"
import ReferralTerms from "../components/referralTerms"

const NotFoundPage = () => (
  <Layout darkHeader={true} seoFields={seoFields} showAnnouncement={false}>
      <ReferralTerms />
  </Layout>
)

const seoFields = {
  description: "Read MProfit's Referral Program Terms & Conditions",
  // lang,
  // meta,
  // keywords,
  title: "Referral Program Terms & Conditions" 
}

export default NotFoundPage
